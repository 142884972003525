/**=====================
    83. Wishlist CSS start
==========================**/
.wishlist {
  table {
    tr {
      td {
        svg {
          color: $danger-color;
          cursor: pointer;
        }
      }
    }
  }
}
/**=====================
    83. Wishlist CSS Ends
==========================**/
