/**=====================
    63. Box-layout CSS start
==========================**/

.box-layout {
  background-image: url(../images/other-images/boxbg.jpg);
  background-blend-mode: overlay;
  background-color: rgba($white, 0.5);

  .page-wrapper {
    &.compact-wrapper {
      .page-body-wrapper {
        div.sidebar-wrapper {
          &.close_icon {
            display: none;
          }
        }
      }
    }
  }
  .colorpicker {
    display: none;
  }

  &.dark-only {
    .page-wrapper {
      .page-header {
        background-color: $dark-card-background;
        .header-wrapper {
          background-color: $dark-card-background;
        }
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .apache-cotainer-large,
    #line-visual,
    #line-visulH {
      > div,
      canvas {
        width: 100% !important;
      }
    }

    .page-wrapper,
    &.page-wrapper {
      &.box-layout {
        padding-top: 40px;
        margin-top: 0 !important;
      }
      &.compact-wrapper {
        margin-top: $box-layout-space;

        .page-body-wrapper {
          .sidebar-wrapper {
            width: 230px;
            top: $box-layout-space;
            border-radius: 10px 0 0 10px;
            max-height: calc(100vh - 80px);

            &.close_icon {
              ~ footer {
                max-width: 100%;
              }

              ~ .footer-fix {
                padding-left: 0;
              }

              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 196px);
                }
              }
            }

            .sidebar-main {
              .sidebar-links {
                margin-top: 10px;
                margin-bottom: 20px;
                height: calc(100vh - 191px);
              }
            }
          }

          .page-body {
            min-height: calc(100vh - 130px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 220px);
            padding-bottom: 80px;
            margin-left: 230px;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }

            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }
        }

        .page-header {
          .header-wrapper {
          }
        }
      }

      &.compact-sidebar {
        .page-header {
          padding-left: 148px;

          .header-wrapper {
          }
        }

        .page-body-wrapper {
          margin-top: 40px;

          div.sidebar-wrapper {
            top: 40px;

            &.close_icon {
              display: none;
              transition: 0.5s;
            }

            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 166px);
                margin: 0;

                li {
                  .sidebar-submenu,
                  .mega-menu-container {
                    left: 470px;
                    top: 40px;
                    height: calc(100vh - 84px);
                  }

                  a {
                    &.active {
                      ~ .sidebar-submenu,
                      ~ .mega-menu-container {
                        animation: unset;
                      }
                    }
                  }
                }
              }
            }
          }

          .footer {
            margin-left: 0;
          }

          .page-body {
            min-height: calc(100vh - 90px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 170px);
            padding-bottom: 80px;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }

            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }
        }

        &.compact-small {
          .page-header {
            padding-left: 90px;

            &.close_icon {
              padding-left: 0;
            }
          }

          .page-body-wrapper {
            div.sidebar-wrapper {
              .sidebar-main {
                .sidebar-links {
                  li {
                    .sidebar-submenu,
                    .mega-menu-container {
                      left: 410px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.horizontal-wrapper {
        &:before {
          display: none;
        }

        .page-body-wrapper {
          margin-top: 40px;

          .page-body {
            margin-top: 80px;
            min-height: calc(100vh - 310px);
            max-height: calc(100vh - 230px);
            overflow: scroll;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }

            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }

          .footer {
            padding-left: 15px;
          }

          .sidebar-wrapper {
            width: 1280px;
            top: 80px;
            margin: 0;
            overflow-x: unset;
            position: relative;

            .sidebar-main {
              #sidebar-menu {
                left: 0%;
                transform: translate(0, 0);
                top: 125px;
                background-color: $white;
              }
            }
          }
        }

        .page-header {
          padding-left: 0;
        }
      }

      &.modern-type {
        .page-header {
          padding-left: 0px;

          .header-wrapper {
            .nav-right {
              flex: 0 0 60%;
              max-width: 60%;
            }
          }
        }

        .page-body-wrapper {
          .page-body {
            margin-left: 260px;
          }

          .sidebar-wrapper {
            top: 120px;

            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 311px);
              }
            }

            &.close_icon {
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 316px);
                }
              }

              ~ footer {
                width: 1280px;
                left: 50%;
                margin-left: 0;
              }
            }
          }

          .footer {
            margin-left: 0;
          }
        }
      }

      .page-header {
        max-width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        padding-left: 230px;
        margin-top: $box-layout-space;
        border-radius: 10px;
        width: unset;

        &.close_icon {
          padding-left: 0;
          margin-left: auto;
          width: calc(100% - 0px);
          transition: $sidebar-transition;

          .header-wrapper {
            border-radius: 10px;
          }
        }

        .header-wrapper {
          border-radius: 0 10px 0 0;
        }
      }

      &.horizontal-wrapper {
        .page-header {
          .header-wrapper {
            .nav-right {
              flex: 0 0 63%;
              max-width: 63%;
            }
          }
        }
      }

      &.material-type {
        .page-body-wrapper {
          margin-top: 0;

          .page-body {
            margin-top: 0;
          }

          .sidebar-wrapper {
            top: 0;
          }
        }
      }

      &.material-icon {
        .page-header {
          &.close_icon {
            margin-top: 40px !important;
          }
        }

        .page-body-wrapper {
          margin-top: 0;

          .page-body {
            top: 0;
            min-height: calc(100vh - 170px);
            padding-bottom: 40px;
          }
        }
      }

      &.advance-layout {
        .page-header {
          box-shadow: 0px 0px 10px rgba($black, 0.07);
        }

        .page-body-wrapper {
          margin-top: 0;
        }
      }

      .page-body-wrapper {
        width: 1280px;
        box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
        margin: 0 auto;
        overflow: hidden;

        .learning-comment {
          margin-left: -14px !important;
          float: left !important;
        }
        .comment-social.learning-comment {
          margin-left: 0 !important;
        }

        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }

        .active-order-table {
          max-width: 443px;
          overflow: auto;

          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }

        .activity {
          .media {
            .gradient-round {
              &.gradient-line-1 {
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }

              &.small-line {
                &:after {
                  height: 36px;
                  bottom: -43px;
                }
              }

              &.medium-line {
                &:after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }

        footer {
          width: 1280px;
          margin: 0 auto;
          padding-left: 230px;
          margin-bottom: 40px !important;
          position: fixed;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 0 0 10px;
        }

        .blockquote-footer {
          margin-left: 0;
          width: 100%;
        }

        .footer-fix {
          padding-left: 230px;
        }

        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;

              > div {
                z-index: 8;
                background-color: rgba(255, 255, 255, 0.75);
                background-blend-mode: overlay;
                width: 100%;
                padding: 30px;
                left: 15px;
              }

              button {
                // width: 40%;
                font-size: 14px;
                margin: 0 auto;
              }
            }

            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;

              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }

            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;

              img {
                width: 60%;
              }
            }

            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }

        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }

        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }

        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;

          img {
            opacity: 0.7;
          }
        }

        .browser-widget {
          img {
            height: 65px;
          }
        }

        .weather-widget-two {
          .bottom-whetherinfo {
            .whether-content {
              top: 39px;
            }
          }
        }

        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
          .card-social {
            li {
              &:nth-child(n + 2) {
                margin-left: 6px;
              }
              a {
                width: 30px;
                height: 30px;
              }
            }
          }
          .card-profile {
            img {
              height: 85px;
              top: 0;
            }
          }
        }

        .select2 {
          width: 901.781px;
        }
      }

      ul {
        &.close_icon {
          > li {
            label {
              padding: 0;

              &:after {
                display: none;
              }
            }
          }
        }
      }

      .bookmark {
        ul {
          margin-right: -1px;
        }
      }

      #batchDelete {
        .jsgrid-grid-header {
          .jsgrid-table {
            tr {
              th {
                .btn {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }
            }
          }
        }
      }

      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type="radio"] {
                display: none;
              }
            }
          }
        }
      }

      .media.bitcoin-graph {
        display: block;

        .top-bitcoin {
          display: inline-block;
          vertical-align: middle;
        }

        .media-body {
          margin-top: 30px;

          .bitcoin-content {
            .bitcoin-numbers {
              h6 {
                font-size: 14px;
              }
            }

            &.text-right {
              text-align: center !important;
            }
          }
        }
      }

      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }

      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }

      .page-builder {
        .ge-canvas.ge-layout-desktop {
          margin-top: $box-layout-space;
        }

        .ge-addRowGroup {
          margin-bottom: 10px;
          display: inline-block;
        }
        .dropdown.ge-layout-mode {
          padding: 0 8px;
        }
      }

      .pricing-wrapper-card {
        padding: 50px 20px;
      }

      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }

      .flot-chart-placeholder {
        &#donut-color-chart-morris-daily {
          min-height: 430px;
        }
      }

      .flot-chart-placeholder {
        &#donut-color-chart-morris {
          min-height: 430px;
        }
      }

      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }

      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }

      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }

      .box-col-4 {
        flex: 0 0 33.33%;
        max-width: 33%;
      }

      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }

          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;
        }

        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }

      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        transition: all linear 0.3s;

        &.show {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          transition: all linear 0.3s;
          padding-bottom: 25px;
        }
      }

      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }

      .social-app-profile {
        .box-col-4 {
          flex: 0 0 30%;
          max-width: 30%;
        }
      }

      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }

        .row {
          .col-xl-6 {
            padding-left: 12px;
          }

          .col-xl-3 {
            + .col-xl-3 {
              padding-right: 12px;
            }
          }
        }

        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }

        .email-content {
          .email-top {
            .user-emailid {
              &:after {
                right: -10px;
              }
            }
          }
        }
      }

      .todo {
        .notification-popup {
          right: 320px;
        }
      }

      .touchspin {
        padding: 0 10px;
      }

      .vertical-menu-main {
        width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;

        .mega-menu {
          width: 1050px !important;
          max-width: 1050px !important;
          left: -320px !important;
        }
      }

      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .file-content {
        .files,
        .folder {
          .file-box,
          .folder-box {
            width: calc(50% - 15px) !important;
            margin-bottom: 10px;
            margin-right: 8px;
            .media {
              display: block;
              text-align: center;
              margin-top: 5px;
            }
          }
        }
      }
      .kanban-board {
        width: calc(100% / 2 - 30px) !important;
      }
      .grid-options {
        ul {
          li:last-child {
            display: none;
          }
        }
      }
      .pro-filter-sec {
        .product-sidebar {
          width: 250px;
          min-width: 250px;
        }
      }
      .product-page-main {
        .proorder-xl-3 {
          order: 3;
        }
      }
      .list-products {
        .table-responsive {
          overflow-x: scroll;
        }
      }
      .prooduct-details-box {
        .btn {
          top: 0;
          left: 0;
          right: unset;
          bottom: unset;
        }
      }
      .pricing-content {
        div {
          .pricing-simple,
          .pricing-block {
            margin-bottom: 20px;
          }
        }
      }
      .email-top {
        .dropdown {
          display: inline-block;
        }
      }
      .email-sidebar {
        .email-left-aside {
          .email-app-sidebar {
            .media {
              display: block;
              text-align: center;
            }
          }
        }
      }
      .edit-profile {
        .profile-title {
          .media {
            display: block;
          }
          .media-body {
            margin-left: 0;
            margin-top: 8px;
          }
        }
      }
      .user-card {
        .custom-card {
          padding: 15px;
          .card-footer {
            h3 {
              font-size: 18px;
            }
          }
        }
      }

      .bookmark-wrap {
        .bookmark-tabcontent {
          .details-bookmark.list-bookmark {
            .bookmark-card {
              .details-website {
                .title-bookmark {
                  .content-general {
                    p {
                      display: none;
                    }
                  }
                  .hover-block {
                    top: 85%;
                  }
                }
              }
              img {
                width: 40% !important;
              }
            }
          }
        }
      }
      .taskadd {
        table {
          tr {
            td:first-child {
              min-width: 160px;
            }
            td:nth-child(3) {
              min-width: 70px;
            }
          }
        }
      }
      .calendar-basic {
        .lnb-calendars {
          display: inline-block;
          .lnb-calendars-d1 {
            display: flex;
          }
          .lnb-calendars-item {
            display: block;
          }
        }
      }
      .user-profile {
        .avatar-showcase {
          .friend-pic {
            margin-right: 11px;
            margin-bottom: 10 px;
          }
        }
        .photos {
          ul {
            li {
              width: 20%;
            }
          }
        }
      }
      .search-page {
        .blog-box.blog-shadow {
          height: 365px;
        }
        .search-vid-block {
          > div:nth-child(n + 1) {
            margin-top: 30px;
          }
        }
      }
      .table-responsive {
        overflow-x: auto;
      }
      .img-cropper {
        .docs-buttons {
          flex: 0 0 100%;
        }
        .docs-toggles {
          flex: 0 0 100%;
          > .btn-group {
            margin-top: 10px;
          }
        }
      }
      .cd-timeline-block {
        .cd-timeline-img {
          width: 45px;
          height: 45px;
          i {
            font-size: 20px;
            left: 48%;
          }
        }
      }
      .blog-list {
        .blog-details {
          p {
            display: none;
          }
          hr {
            display: none;
          }
        }
      }
      .blog-box.blog-shadow {
        height: 365px;
        .blog-details {
          padding: 15px;
        }
      }

      .comment-box {
        ul {
          ul {
            margin-left: 50px;
            text-align: right;
          }
        }
      }
      .job-search {
        .media {
          .media-body {
            .job-apply-btn {
              position: unset;
              width: fit-content;
              margin-top: 5px;
              display: block;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1660px) and (min-width: 1440px) {
    .calendar-sec {
      order: unset;
    }

    .chart_data_right.second {
      display: none !important;
    }
  }
}

/**=====================
    63. Box-layout CSS ends
==========================**/
