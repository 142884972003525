/**=====================
     39. Table  CSS Start
==========================**/
.table-bordered {
  td,
  th {
    border-color: $light-gray !important;
  }
}
.pills-component {
  width: 20%;
}
.product-page-width {
  width: 80%;
}
.table {
  margin-bottom: $table-b-margin;
  th,
  td {
    padding: $table-padding;
  }
  th {
    color: $table-heading-color;
  }
  td {
    color: $table-row-color;
  }
  [class*="bg-"] {
    color: $white;
  }
  .bg-light {
    color: $theme-body-font-color;
  }
  tfoot {
    font-weight: $table-footer-font-weight;
  }

  thead {
    &.bg-primary {
      tr {
        th {
          color: $white;
        }
      }
    }
    &.table-primary {
      background-color: rgba($primary-color, 0.1);
      --bs-table-bg: rgba($primary-color, 0.1);
      color: $primary-color;
      tr {
        th {
          color: $primary-color;
        }
      }
    }
  }
}
.table-primary {
  thead {
    tr {
      th {
        color: $white;
      }
    }
  }
  tbody {
    tr {
      th:nth-child(1) {
        color: $white;
      }
      td {
        color: $white;
      }
    }
  }
}
.table-inverse {
  th,
  td {
    color: $white;
  }
}
.table[class*="bg-"] {
  th,
  td {
    color: $white;
  }
}
.table-hover {
  tbody {
    tr:hover {
      background-color: rgba($primary-color, 0.1);
      --bs-table-accent-bg: unset;
      th,
      td {
        color: $primary-color;
      }
    }
  }
}
.table-dark {
  th {
    color: $white;
  }
}
//Sizing class
.table-xl {
  td,
  th {
    padding: $table-xl-padding;
    font-size: $table-xl-font;
  }
}
.table-lg {
  td,
  th {
    padding: $table-lg-padding;
    font-size: $table-lg-font;
  }
}
.table-de {
  td,
  th {
    padding: $table-de-padding;
  }
}
.table-sm {
  th,
  td {
    padding: $table-sm-padding;
    font-size: $table-sm-font;
  }
}
.table-xs {
  th,
  td {
    padding: $table-xs-padding;
    font-size: $table-xs-font;
  }
}

//Table Border
.table-border-horizontal {
  tr,
  th,
  td {
    border-top: 1px solid $horizontal-border-color;
    padding: $horizontal-padding;
  }
}
.table-border-vertical {
  tbody {
    th,
    td {
      border-bottom: 0;
    }
  }
  tr,
  th,
  td {
    border-top: 0;
    border-right: 1px solid $horizontal-border-color;
  }
  tr {
    td {
      &:last-child {
        border-right: none;
      }
    }
  }
}

.table-bordernone {
  thead {
    border-top: 1px solid $horizontal-border-color;
    th {
      border-bottom: 0;
    }
  }
  tbody {
    border: 0;
    th {
      border: 0;
    }
  }
  td {
    border: 0;
  }
  .bd-t-none {
    border-top: none;
  }
  .u-s-tb {
    padding: 11px;
  }
}
.table-double {
  border-left: 4px double #dee2e6;
  border-right: 4px double #dee2e6;
  tr {
    border-style: double;
    border-width: 4px;
    border-right: 0;
    border-left: 0;
  }
}
.table-dotted {
  border-left: 2px dotted #dee2e6;
  border-right: 2px dotted #dee2e6;
  tr {
    border-style: dotted !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
}
.table-dashed {
  border-left: 2px dashed #dee2e6;
  border-right: 2px dashed #dee2e6;
  tr {
    border-style: dashed !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
}
.table-inverse {
  color: $white;
  background-color: $table-inverse-bg-color;
  th,
  td {
    border-color: $white;
  }
  &.table-bordered {
    border: 0;
  }
}

.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: rgba(245, 247, 250, 0.5);
      }
    }
  }
}
.table-bordered {
  td,
  th {
    border-color: $light;
  }
}

.table {
  > :not(:last-child) {
    > :last-child {
      > * {
        border-color: $light-gray;
      }
    }
  }
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: $light-gray;
}

.table-styling {
  &.table-primary {
    &.table-striped {
      tbody {
        tr {
          color: $white;
          &:nth-of-type(odd) {
            // background-color:rgba($primary-color,0.1);
            --bs-table-accent-bg: unset;
            &:hover {
              color: $primary-color;
            }
          }
          &:nth-of-type(even) {
            &:hover {
              th,
              td {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.table-success {
  background-color: rgba($success-color, 0.1);
  // --bs-table-bg: rgba($success-color, 0.1);
  color: $success-color;

  tr {
    th {
      color: $primary-color;
    }
  }
}

/**=====================
     39.Table CSS Ends
==========================**/
