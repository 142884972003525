/**=====================
     11. Form Builder 1 CSS Start
==========================**/
.form-builder {
  .form-builder-column {
    .form-builder-2-header {
      .navbar {
        select {
          background-color: #fff;
          color: #717171;
        }
      }
    }
    .theme-form {
      label {
        font-weight: 500;
      }
    }
    .drag-bx {
      h6 {
        font-weight: 500;
      }
    }
  }
  .form-builder-header-1 {
    margin-bottom: 30px;
    h6 {
      font-weight: 500;
    }
  }
  .theme-form {
    #radioscheckboxes {
      .component {
        .radio,
        .checkbox {
          label {
            font-weight: 500;
          }
        }
      }
    }
    input,
    select,
    input[type="form-control"] {
      padding: 6px 12px !important;
    }
  }
  .drag-box {
    user-select: none;
    fieldset {
      border: 1px dotted $light-gray;
      min-height: 655px;
      padding: 30px;
      width: 100%;
      overflow: hidden;
      margin-top: 30px;
      border-radius: 4px;
      h6 {
        font-weight: 500;
      }
    }
  }

  .component {
    cursor: pointer;
    .input-group {
      .btn {
        line-height: 1.8;
      }
    }
    label {
      padding-top: 8px;
    }
    .form-group {
      input,
      textarea,
      select {
        border-radius: 4px;
      }
      .input-group {
        #prependedcheckbox,
        #prependedtext {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        #appendedcheckbox {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right-color: transparent;
        }
        #appendedtext,
        #buttondropdown {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        #buttondropdown {
          margin-right: -1px;
        }
        .input-group-btn {
          button {
            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
        .input-group-prepend {
          .btn-right,
          .checkbox-radius {
            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      .pl-md-radios {
        padding-left: 37px;
      }
    }
  }
  input {
    cursor: pointer;
  }
  textarea {
    resize: vertical;
  }
  .popover {
    z-index: 8;
    opacity: 1;
    left: -290px !important;
    top: 95px !important;
    .control-group {
      cursor: default;
    }
    input {
      cursor: pointer;
    }
  }
  .render {
    min-height: 500px;
    width: 100%;
    border-radius: 4px;
    background-color: #f7f6ff;
    border-color: $light-gray;
  }
  .popover {
    .controls {
      margin-left: 0;
    }
    .control-label {
      text-align: left;
    }
    form {
      width: 100%;
    }
    .fade.left.in {
      left: -215px;
    }
  }
  .target {
    &:after {
      float: left;
      content: " ";
      height: 77px;
      margin-top: 3px;
      background: $light-gray;
      width: 100%;
      border: 1px dashed $light-gray;
    }
  }
}
.drag {
  form {
    padding: 10px 5px;
    border: 1px dotted $light-gray;
    position: absolute;
    background: $white;
    box-shadow: 0 0 30px $light-gray;
    overflow: hidden;
    input {
      border-radius: 5px;
    }
  }
}
.form-builder-header-1 {
  background-color: $light-gray;
  padding: 15px;
  border-radius: 5px;
  min-height: 67px;
  h6 {
    margin-bottom: 0;
    margin-top: 10px;
    line-height: 2.2;
  }
}
.form-builder {
  .help-block {
    font-size: 12px;
  }
  input {
    border-radius: 4px !important;
  }
  .form-group {
    .col-md-12 {
      padding: 0;
    }
  }
  .popover {
    width: 300px;
  }
  .popover-header {
    background-color: $primary-color;
    padding: 15px;
  }
  .popover-body {
    padding: 15px;
    .form-group {
      &.row {
        padding: 0 15px;
        .col-lg-12 {
          padding: 0;
        }
      }
    }
  }
}

.dropdown-menu {
  background-color: $light-background;
}

@media only screen and (max-width: 991px) {
  .form-builder {
    h6 {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .form-builder {
    .component {
      cursor: pointer;
      .input-group {
        .btn {
          line-height: 1.5;
        }
      }
    }
  }
}
/**=====================
    11. Form Builder 1 CSS Ends
==========================**/
