/**=====================
      08. GRID CSS Start
==========================**/
.grid-showcase {
  margin-bottom: -10px;

  span {
    display: block;
    border: 1px solid $light-color;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: $white;
  }
}

.grid-align {
  margin-bottom: -30px;

  .row {
    padding: 15px 0;
    min-height: 10rem;
    border: 1px solid rgba($primary-color, 0.02);
    margin-bottom: 30px;
    background-color: rgba($primary-color, 0.02);
  }
}

.grid-showcase{
  .code-box-copy{
    span {
      display: unset;
      border:unset;
      padding: unset;
      margin-bottom: unset;
      text-align: unset;
      background-color: unset;
    }
  }
}

/**=====================
      08. GRID CSS Ends
==========================**/